<template>
  <div>
    <div class="block">
      <vm-search label="时间范围开始">
        <el-date-picker
          v-model="filter.createTimeFrom"
          type="month"
          placeholder="选择月">
        </el-date-picker>
      </vm-search>
      <vm-search label="时间范围结束">
        <el-date-picker
          v-model="filter.createTimeTo"
          type="month"
          placeholder="选择月">
        </el-date-picker>
      </vm-search>
      <el-button type="primary" style="margin-bottom: 5px;margin-left: 5px" @click="fetch">搜索</el-button>
      <el-button type="primary" style="margin-bottom: 5px;margin-left: 5px" @click="refetch">重置</el-button>
      <!--      <el-button type="primary" style="margin-bottom: 5px;margin-left: 5px" @click="exportExcelData">导出</el-button>-->
    </div>


    <div class="box">
      故障率=故障电梯数/电梯总数
      <div id="faultBar" style="width: 700px;height: 300px"></div>
      故障率=维修工单数/电梯运行次数
      <div id="elevatorBar" style="width: 700px;height: 300px"></div>
      困人率=困人次数/电梯总数
      <div id="peopleBar" style="width: 700px;height: 300px"></div>
    </div>
    <div class="box">
    </div>
    <rescue-time></rescue-time>
  </div>
</template>

<script>
  import * as echarts from "echarts";
  import {export2Excel} from "@/util";
  import moment from "moment";
  import loginUtil from "@/util/loginUtil";
  import RescueTime from "@/views/dataStatistics/RescueTime";

  export default {
    name: "ElevatorFaults",
    components:{RescueTime},
    data() {
      return{
        faultList:[],
        peopleList:[],
        teamOrder:[],
        faultOrder:[],
        realEstateOrder:[],
        receiverOrder:[],
        excelArr:[],
        value:true,
        filter:{
          maintenanceType:"as_need",
          companyId: loginUtil.getCompanyCode(),
        },
      };
    },
    mounted() {
      this.fetch();
    },
    methods:{
      refetch() {
        this.filter={};
        this.fetch();
      },
      handleTime() {
        if(this.filter.seTime !=null) {
          this.filter.createTimeFrom = this.filter.seTime[0];
          this.filter.createTimeTo = this.filter.seTime[1];
        }else {
          this.filter.createTimeFrom = "";
          this.filter.createTimeTo = "";
        }
      },
      exportExcelData() {
        const title={
          dateTime:"日期",
          dateNum:"工单数",
          teamName:"维保班组",
          teamNum:"工单数",
          realEstateName:"楼盘名称",
          realEstateNum:"工单数",
          faultName:"故障类型",
          faultNum:"工单数",
          receiverName:"接单人",
          receiverNum:"工单数",
        };
        export2Excel(title,this.excelArr,`数据统计-故障统计-${this.excelArr.length}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
      },
      change() {
        this.faultBar();
      },
      fetch() {
        console.log(this.filter,105);
        if (this.filter.date instanceof Array && this.filter.date.length>0) {
          this.filter.curDate  = this.filter.date.map(item=>moment(item).format("YYYY-MM-DD"));
        }else {
          this.filter.curDate=[];
        }
        if (this.filter.createTimeFrom||this.filter.createTimeTo) {
          if (!this.filter.createTimeFrom||!this.filter.createTimeTo) {
            return this.$message.error("请选择开始和结束时间");
          }
          this.$http.get("elevator/elevator-fault-rate/list",{...this.filter,createTimeFrom:moment(this.filter.createTimeFrom).format("YYYY-MM-DD 00:00:00"),createTimeTo:moment(this.filter.createTimeTo).format("YYYY-MM-DD 00:00:00")}).then(res=>{
            this.faultList = res;
            this.elevatorBar();
            this.faultBar();
          });
          this.$http.get("maintenance/repair-report/people-trapped-rate/list",{...this.filter,createTimeFrom:moment(this.filter.createTimeFrom).format("YYYY-MM-DD 00:00:00"),createTimeTo:moment(this.filter.createTimeTo).format("YYYY-MM-DD 00:00:00")}).then(res=>{
            this.peopleList = res;
            this.peopleBar();

          });
        }else {
          this.$http.get("elevator/elevator-fault-rate/list",{          companyId: loginUtil.getCompanyCode(),
          }).then(res=>{
            this.faultList = res;
            this.faultBar();
            this.elevatorBar();

          });
          this.$http.get("maintenance/repair-report/people-trapped-rate/list",{          companyId: loginUtil.getCompanyCode(),
          }).then(res=>{
            this.peopleList = res;
            this.peopleBar();

          });
        }

      },

      faultBar() {
        const chart = echarts.init(document.getElementById("faultBar"));

        const dateList =  this.faultList.map(item=>item.date);

        const dataList = this.faultList.map(item=>(item.faultStatistics/2.2).toFixed(2));
        console.log(dataList,dateList,116);
        const  option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
            formatter:(parmas)=> {
              return parmas[0]?.value+"%";
            },
          },
          title: {
            left: "center",
            text: "故障率按电梯数算",
          },
          xAxis: {
            type: "category",
            data: dateList,
            nameTextStyle:{
              overflow:"breakAll",
            },
            axisLabel: { interval: 0, rotate: 60 },
            axisTick:{alignWithLabel:true},
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: dataList,
              type: "bar",
              label:{
                show:true,
                color:"red",
                fontSize:8,
                position:"top",
                formatter:(parmas)=> {
                  return parmas.value+"%";
                },

              },
            },
          ],
        };
        chart.setOption(option);
      },
      elevatorBar() {
        const chart = echarts.init(document.getElementById("elevatorBar"));

        const dateList =  this.faultList.map(item=>item.date);

        const dataList = this.faultList.map(item=>item.elevatorStatistics);
        console.log(dataList,dateList,116);
        const  option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
            formatter:(parmas)=> {
              return (parmas[0]?.value *100).toFixed(2)+"‱";
            },
          },
          title: {
            left: "center",
            text: "故障率按运行次数算",
          },
          xAxis: {
            type: "category",
            data: dateList,
            nameTextStyle:{
              overflow:"breakAll",
            },
            axisLabel: { interval: 0, rotate: 60 },
            axisTick:{alignWithLabel:true},
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: dataList,
              type: "bar",

              label:{
                show:true,
                color:"red",
                fontSize:8,
                position:"top",
                formatter:(parmas)=> {
                  return (parmas.value *100).toFixed(2) +"‱";
                },

              },
            },
          ],
        };
        chart.setOption(option);
      },
      peopleBar() {
        const chart = echarts.init(document.getElementById("peopleBar"));

        const dateList =  this.peopleList.map(item=>item.date);
        const dataList =  this.peopleList.map(item=>item.peopleTrappedRate);
        console.log(dataList,dateList,116);
        const  option = {
          title: {
            left: "center",
            text: "困人率",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
            formatter:(parmas)=> {
              return (parmas[0]?.value*100).toFixed(2)+"‱";


            },
          },
          xAxis: {
            type: "category",
            data: dateList,
            nameTextStyle:{
              overflow:"breakAll",
            },
            axisLabel: { interval: 0, rotate: 60 },
            axisTick:{alignWithLabel:true},
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: dataList,
              type: "bar",
              label: {
                show: true,
                color:"red",
                fontSize:8,
                position:"top",
                formatter: (parmas) => {
                  return (parmas.value*100).toFixed(2) + "‱";
                },
              },
            },
          ],
        };
        chart.setOption(option);
      },
    },
  };
</script>

<style scoped>
.box{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
}
.content{
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap:wrap
}
</style>
